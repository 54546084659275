import React from 'react';

function AdvisoryBoard() {
  return (
    <section>
      <div className="max-w-5xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4">Advisory board</h2>
            <p className="text-xl text-gray-400">ThreatAgility has assembled an Advisory Board of highly experienced and qualified business leaders to provide functional guidance and business oversight.</p>
          </div>

          {/* Team members */}
          <div className="z-10 sm:flex sm:flex-wrap sm:justify-center text-center -my-4 sm:-my-8 sm:-mx-3" data-aos-id-aboard>

            <div className="z-10 sm:w-1/2 md:w-1/3 lg:w-1/3 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-anchor="[data-aos-id-aboard]">
              <div className="flex flex-col items-center text-center text-center text-center">
                <img className="rounded-full mb-4" src={require('../images/aboutus/md.jpg').default} width="120" height="120" alt="Profile Photo" />
                <h4 className="text-xl font-medium mb-1">Marc DaCosta</h4>
                <div className="text-red-500 mb-1">Data Science, <br/>Cloud Scale Development</div>
                <div className="text-gray-500 mb-1">Co-founder<br/> Enigma Technologies</div>
              
                {/* <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="#0">@laraamprecht</a> */}
              </div>
            </div>

            <div className="z-10 sm:w-1/2 md:w-1/3 lg:w-1/3 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-delay="100" data-aos-anchor="[data-aos-id-aboard]">
              <div className="flex flex-col items-center text-center text-center text-center">
                <img className="rounded-full mb-4" src={require('../images/aboutus/cs.jpg').default} width="120" height="120" alt="Profile Photo" />
                <h4 className="text-xl font-medium mb-1">Craig Smith</h4>
                <div className="text-red-500 mb-1">Automotive Threat Modeling <br/>and Product Security</div>
                <div className="text-gray-500 mb-1">Rapid 7,<br/>Bird Rides</div>
              </div>
            </div>

            <div className="z-10 sm:w-1/2 md:w-1/3 lg:w-1/3 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-delay="100" data-aos-anchor="[data-aos-id-aboard]">
              <div className="flex flex-col items-center text-center text-center text-center">
                <img className="rounded-full mb-4" src={require('../images/aboutus/kd.jpg').default} width="120" height="120" alt="Profile Photo" />
                <h4 className="text-xl font-medium mb-1">Karl Duffield</h4>
                <div className="text-red-500 mb-1">ESP Leadership and Sales, <br/>Engineering Management</div>
                <div className="text-gray-500 mb-1">Iconiq Motors, Ricardo, <br/>Magna Steyr</div>
              </div>
            </div>

            <div className="z-10 sm:w-1/2 md:w-1/3 lg:w-1/3 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-delay="400" data-aos-anchor="[data-aos-id-aboard]">
              <div className="flex flex-col items-center text-center">
                <img className="rounded-full mb-4" src={require('../images/aboutus/ol.jpg').default} width="120" height="120" alt="Profile Photo" />
                <h4 className="text-xl font-medium mb-1">Olivier Lobey</h4>
                <div className="text-red-500 mb-1">OEM and Supplier <br/>Engineering Mgmt.</div>
                <div className="text-gray-500 mb-1">Huawei Technologies, <br/>Byton, Continental</div>
                {/* <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="#0">@dominikprasad</a> */}
              </div>
            </div>

            <div className="z-10 sm:w-1/2 md:w-1/3 lg:w-1/3 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-delay="300" data-aos-anchor="[data-aos-id-aboard]">
              <div className="flex flex-col items-center text-center">
                <img className="rounded-full mb-4" src={require('../images/aboutus/gg.jpg').default} width="120" height="120" alt="Profile Photo" />
                <h4 className="text-xl font-medium mb-1">Gerry Gialias</h4>
                <div className="text-red-500 mb-1">Scalable Cloud<br/> Infrastructure</div>
                <div className="text-gray-500 mb-1">Aethyr.io, Telstra, BT, <br/>Commonwealth Bank</div>
                {/* <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="#0">@timothychandran</a> */}
              </div>
            </div>

            <div className="z-10 sm:w-1/2 md:w-1/3 lg:w-1/3 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-delay="500" data-aos-anchor="[data-aos-id-aboard]">
              <div className="flex flex-col items-center text-center">
                <img className="rounded-full mb-4" src={require('../images//aboutus/mf.jpg').default} width="120" height="120" alt="Profile Photo" />
                <h4 className="text-xl font-medium mb-1">Mario Falchoni</h4>
                <div className="text-red-500 mb-1">Investor Relations<br/>Reputation Management</div>
                <div className="text-gray-500 mb-1">Retorix, Reputation Edge<br/>Spark Infrastructure</div>
                {/* <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="#0">@daryamagic</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdvisoryBoard;